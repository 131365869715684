<template>
  <div class="h-full dsv1__Page min-h-screen">
    <NuxtPage />
  </div>
</template>
<script lang="ts" setup>
const host = useUniversalHost()
useHead({
  bodyAttrs: {
    class: 'dsv1'
  }
})
</script>